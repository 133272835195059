/* eslint-disable no-useless-escape */
export default (t) => {
    return [
        {
            question: t('Why does the commercial package include fewer engines than the demo account?'),
            answer: (<> {t('OPSWAT has explicit agreements for redistributing the scanning results only with all the vendors in the commercial offering')} </>)
        },
        {
            question: t('What happens to files after scanning?'),
            answer: (<p dangerouslySetInnerHTML={{ __html: t('If you have a commercial license your file will be deleted after scanning when using private processing at upload time. All other files can be kept by OPSWAT and, if infected, shared as part of malware sharing programs. For more details see our <a href=\"https://www.opswat.com/legal/privacy-policy\" rel=\"noopener noreferrer\" target=\"_blank\">Privacy Policy</a>') }}/>)
        },
        {
            question: t('Are Deep CDR requests charged extra?'),
            answer: (<> {t('No, all static operations performed when a file is uploaded, including Deep CDR are charged as one request')} </>)
        },
        {
            question: t('Are hash lookup requests charged if the hash is not found?'),
            answer: (<> {t('Hash lookup requests that return not found requests are charged with a ration of 5:1 For each 5 hash lookups not found, we charge 1 request')} </>)
        },
        {
            question: t('What is the source of your hash database?'),
            answer: (<> {t('All hashes come from files that OPSWAT has scanned in time from various sources in including malware sharing programs, users, and integrations with other OPSWAT products')} </>)
        },
        {
            question: t('How can I get a demo account?'),
            answer: (<> {t('Anyone can register for a free account. Click the \"Sign in\" button from the header bar to go to our register page and signup with your email address to start using MetaDefender Cloud immediately. All new accounts also receive an apikey for API access')} </>)
        },
        {
            question: t('Am I charged extra if I exceed my daily limits?'),
            answer: (<> {t('For commercial users, when the daily limit is reached, the service will not accept any more requests, throwing an HTTP 429 error for limit exceeded')} <br /> {t('For enterprise customers, there is a 10% limit exceed permitted, and depending on the contract, additional requests can be allowed for a fixed price per request')} </>)
        },
        {
            question: t('What certifications does MetaDefender Cloud have?'),
            answer: (<p dangerouslySetInnerHTML={{ __html: t('For a full list of certifications please go to <a href=\"https://www.opswat.com/company/compliance-and-certifications\" rel=\"noopener noreferrer\" target=\"_blank\">https://www.opswat.com/company/compliance-and-certifications</a>') }}/>)
        },
        {
            question: t('How am I charged for unarchiving files?'),
            answer: (<> {t('Each file inside an archive will be considered an individual file and the limit will be charged accordingly')} </>)
        },
        {
            question: t('How many hashes do you have?'),
            answer: (<> {t('The MetaDefender Cloud hash database has over 40 billion hashes, and the number is constantly increasing. For accessing the hashes a "Reputation API" license is required')} </>)
        }
    ];
};

/* eslint-disable no-useless-escape */
export default (t) => {
    return [
        {
            title: '',
            className: 'details',
            licensingPlan: [
                t('Number of engines'),
                t('File size limit ¹'),
                t('Throttling (req/min) ²'),
                t('Prevention limit'),
                t('Max files in archive'),
                t('Archive recursion level'),
                t('Request priority (queue)'),
                t('Availability SLA'),
                t('Response time SLA ³'),
                t('RTO'),
                t('RPO'),
                t('Monthly rate limits'),
                t('Ability to exceed limit ⁴'),
                t('Deep CDR ⁵'),
                t('Private Scanning'),
                t('Private Processing'),
                t('End to End Encryption ⁶'),
                t('Organizations'),
                t('Reporting'),
                t('Notifications/Alerts'),
                t('IP Range Restrictions'),
                t('mTLS'),
                t('Customer Provided IDP'),
                t('Custom Domain')
            ]
        },
        {
            title: t('Standard'),
            className: 'standard',
            licensingPlan: [
                '10',
                '140 MB',
                '75',
                t('Daily Cap'),
                '500',
                '5',
                t('Low'),
                '99.5%',
                [
                    {
                        text: t('8 seconds p50')
                    },
                    {
                        text: t('500ms API calls p50')
                    }
                ],
                t('24 hours'),
                t('24 hours'),
                '-',
                '-',
                'Yes',
                'Yes',
                'Yes',
                'Yes',
                '-',
                '-',
                '-',
                '-',
                '-',
                '-',
                '-'
            ]
        },
        {
            title: t('Professional'),
            className: 'professional',
            licensingPlan: [
                '15',
                '256 MB',
                '150',
                t('Daily Cap'),
                '10,000',
                '25',
                t('Medium'),
                '99.5%',
                [
                    {
                        text: t('8 seconds p75')
                    },
                    {
                        text: t('500ms API calls p75')
                    }
                ],
                t('12 hours'),
                t('12 hours'),
                '-',
                t('Yes (20%)'),
                'Yes',
                'Yes',
                'Yes',
                'Yes',
                'Yes',
                'Yes',
                'Yes',
                '-',
                'Yes',
                '-',
                '-'
            ]
        },
        {
            title: t('Enterprise '),
            className: 'enterprise',
            licensingPlan: [
                '20+',
                '1 GB+',
                '500',
                t('Daily or Monthly Cap'),
                '100,000',
                '50',
                t('High'),
                '99.9%',
                [
                    {
                        text: t('8 seconds p90')
                    },
                    {
                        text: t('500ms API calls p90')
                    }
                ],
                t('4 hours'),
                t('4 hours'),
                'Yes',
                t('Yes (50%)'),
                'Yes',
                'Yes',
                'Yes',
                'Yes',
                'Yes',
                'Yes',
                'Yes',
                'Yes',
                'Yes',
                'Yes',
                'Yes'
            ]
        }
    ];
};

